import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Selection, CommandBar, IColumn, ICommandBarItemProps, SelectionMode, PanelType, Panel, PrimaryButton, Stack, SearchBox, Modal, getTheme, mergeStyleSets, FontWeights, Dialog, Checkbox, DialogFooter, DialogType } from '@fluentui/react';
import { downloadSingleTournamentCredential, downloadTournamentBadgeForStudent, downloadTournamentParticipants, getAthletes, getTournamentCredentialTemplates, getViralJoinEnv, postAthleteUpdate, readInputsForStudent } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { useMediaQuery } from 'react-responsive';
import List, { IDocument } from '../../../components/List';
import { useBoolean } from '@fluentui/react-hooks';
import { ICustomValue, IStudent, Student } from '../../../model/Student';
import { DateTime } from 'luxon';
import StudentEditor from '../../../components/StudentEditor';
import { Level } from '../../../model/Level';
import { Program } from '../../../model/Program';
import FileDownloader from '../../../components/FileDownloader';
import { IUserInputWithStudents } from '../../../model/CatalogItem';
import { IFileDownload } from '../../../model/FileDownload';
import { useIdentity } from '../../../Identity';
import { TournamentStakeholderRoles } from '../../../model/TournamentReviewDashboardViewModel';

interface IData {
    tournamentId: string;
}

enum ExportType {
    AthleteList = 0,
    Credential = 1
}

interface ITableRow extends IDocument {
  key: string;
  firstName: string;
  lastName: string;
  specialNeeds: boolean;
  dob: string;
  weight: number;
  height: number;
  trainingFacility: string;
  customValues: ICustomValue[];
  registeredEvents: string;
}

const AthleteEditor: FC<IData> = ({ tournamentId }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const identity = useIdentity();
    
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

  const [errorCode, setErrorCode] = useState<number>();
  const [athletes, setAthletes] = useState<Student[]>();

  const [items, setItems] = useState<ITableRow[]>();
  const [allItems, setAllItems] = useState<ITableRow[]>();
  const [, setSelection] = useState<Selection>(new Selection());
  const [selectedItem, setSelectedItem] = useState<ITableRow>();
  
  const [downloadSubItems, setDownloadSubItems] = useState<ICommandBarItemProps[]>([]);

  const [columns, setColumns] = useState<IColumn[]>([
    {
      key: 'firstname',
      name: 'First Name',
      fieldName: 'firstName',
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true
    },
    {
      key: 'lastname',
      name: 'Last Name',
      fieldName: 'lastName',
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true
    },
    {
      key: 'specialneeds',
      name: 'Special Needs',
      fieldName: 'specialNeeds',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'boolean',
      isPadded: true
    },
    {
      key: 'dob',
      name: 'Date of birth',
      fieldName: 'dob',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,      
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true
    },
    {
      key: 'weight',
      name: 'Weight',
      fieldName: 'weight',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'number',
      isPadded: true
    },
    {
      key: 'height',
      name: 'Height',
      fieldName: 'height',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'number',
      isPadded: true
    },
    {
      key: 'trainingFacility',
      name: 'Training Facility',
      fieldName: 'trainingFacility',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true
    },
    {
      //add column for events
      key: 'registeredEvents',
      name: 'Registered Events',
      fieldName:"registeredEvents",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true
    }]);
  
  useEffect(() => {
    if (athletes === undefined) {
      return;
    }

    var tableRows = buildTableRows(athletes);
    setAllItems(tableRows);
    setItems([...tableRows]);
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  }, [athletes]);

  const buildTableRows = (athletes: IStudent[]) => {
    var tableRows = new Array<ITableRow>();

    //we need to dynamically build columns for the custom values, not every student will have a value for each column
    let columnHeaders = new Array<string>();
        
    for (let a of athletes) {
      let title = `${a.FirstName} ${a.LastName}`;

      for (let cv of a.CustomValues) {
        if (columnHeaders.indexOf(cv.Key) === -1) {
          columnHeaders.push(cv.Key);
        }
      }

      tableRows.push({
        customValues: a.CustomValues,
        firstName: a.FirstName,
        lastName: a.LastName,
        specialNeeds: a.SpecialNeeds,
        dob: a.DateOfBirth === undefined ? '' : DateTime.fromJSDate(a.DateOfBirth).toLocaleString(DateTime.DATE_SHORT),
        weight: a.Weight,
        height: a.Height,
        trainingFacility: a.TrainingFacility,
        getTitle: () => title,
        key: a.Id,
        registeredEvents: a.EventRegistrations === undefined ? '' : a.EventRegistrations.join(', ')
      });
    }

    let newColumns: IColumn[] = [];

    for (let cHeader of columnHeaders) {
      newColumns.push({
        key: `${cHeader}-${Math.random()}`,
        name: cHeader,
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (row: ITableRow) => row.customValues.find(cv => cv.Key === cHeader)?.Value
      });
    }

    setColumns([...columns, ...newColumns]);
          
    return tableRows;
  }

    

  useEffect(() => {
    if (errorCode === undefined) {
      return;
    }

    throw new Error("The server returned status code: " + errorCode);
  }, [errorCode]);

  const [allPrograms, setAllPrograms] = useState<Program[]>([]);
  const [allLevels, setAllLevels] = useState<Level[]>([]);
  
  const [templateId, setTemplateId] = useState<string>();
  const [exportType, setExportType] = useState<ExportType>();
    
  useEffect(() => {
    const fetchAsync = async () => {
      var allAthletesInTournament = await getAthletes(instance, account!, tournamentId);
            
      if (typeof allAthletesInTournament === 'number') {
        setErrorCode(allAthletesInTournament);
        return;
      }

      var credentialTemplates = await getTournamentCredentialTemplates(instance, account!, tournamentId);

      let templateItems: ICommandBarItemProps[] = [{
        key: 'usletter',
        text: 'US Letter Paper',
        iconProps: { iconName: 'Print' },
        onClick: (event, item) => { setTemplateId(item?.key); setExportType(ExportType.Credential); showDownloadModal(); }
      }];

      for (let template of credentialTemplates) {
        templateItems.push({
          key: template.Id,
          text: template.DisplayName,
          iconProps: { iconName: 'Print' },
          onClick: (event, item) => { setTemplateId(item?.key); setExportType(ExportType.Credential); showDownloadModal(); }
        });
      }

      setDownloadSubItems(templateItems);

      var env = await getViralJoinEnv();
      var programs = env!.Programs;
      var levels = env!.Levels

      setAllPrograms(programs);
      setAllLevels(levels);

      setAthletes(allAthletesInTournament);
    }

    fetchAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [tournamentId, account, instance]);

  const [isDownloadModalOpen, { setTrue: showDownloadModal, setFalse: hideDownloadModal }] = useBoolean(false);
    
  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'edit',
      text: 'Edit',
      iconProps: { iconName: 'Edit' },
      disabled: selectedItem === undefined,
      onClick: () => { openStudentEditor(athletes?.find(a => a.Id === selectedItem?.key)) }
    },
    {
      key: 'downloadCredential',
      text: 'Download credential',
      iconProps: { iconName: 'IDBadge' },
      disabled: selectedItem === undefined || identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Coach]),
      subMenuProps: {
        items: downloadSubItems
      }
    }
  ];
    
  const farCommandBarItems: ICommandBarItemProps[] = [
    {
      key: 'downloadparticipants',
      disabled: identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Coach]),
      text: 'Download all',
      iconProps: { iconName: 'DownloadDocument' },
      onClick: () => { setExportType(ExportType.AthleteList); showDownloadModal(); }
    }
  ];
          
  const doSearch = (n: string | undefined) => {
      
    if (n === undefined || n.length === 0) {
      //rebuild the default list
      var defaultList = buildTableRows(athletes!);
      setItems(defaultList);
      return;
    }
  
    var filtered = allItems?.filter(i =>
      i.getTitle().toLowerCase().indexOf(n.toLowerCase()) !== -1);

    setItems([...filtered!]);
  }

  const [diffStudent, setDiffStudent] = useState<Student>();
  const [editStudent, setEditStudent] = useState<Student>();
  const [isEditValid, setIsEditValid] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [customValues, setCustomValues] = useState<IUserInputWithStudents[]>();
  const [isFetchingCustomValues, setIsFetchingCustomValues] = useState<boolean>(false);
  
  const openStudentEditor = (student?: Student) => {
    var isCoach = identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Coach]);

    if (isCoach) {
      toggleCoachDialog();
      
      if (!hasConfirmedConsent) {
        return;
      }
    }
    else {
      proceedToEdit(student);
    }
  }

  const proceedToEdit = (student?: Student) => {
    setDiffStudent(JSON.parse(JSON.stringify(student)));
    setEditStudent(student);
      
    if (student !== undefined) {
      openStudentEditorAsync(student);
      return;
    }
      
    openEditPanel();
  }

  const openStudentEditorAsync = async (student: Student) => {
    setIsFetchingCustomValues(true);
    openEditPanel();

    var customValues = await readInputsForStudent(instance, account!, tournamentId, student!.Id);

    if (typeof customValues !== 'number') {
      setCustomValues(customValues);
    }

    setIsFetchingCustomValues(false);
  }
  
  const onActiveItemChanged = (item?: any, index?: number) => {
    setSelectedItem(item);
  }
  const saveStudent = async () => {
    setIsSaving(true);

    var result = await postAthleteUpdate(instance, account!, editStudent!, tournamentId);
    
    if (!result?.ok) {
      alert("Sorry, something went wrong.");
    }
    else {
      //remove this item then readd
      var idx = items?.findIndex(i => i.key === editStudent?.Id);
      var toAdd = items![idx!];
      
      items?.splice(idx!, 1);
      setItems([...items!]);
      setItems([...items!, toAdd!]);
    }

    dismissEditPanel();
    setIsSaving(false);
    setIsEditValid(false);
    setHasChange(false);
  }
  const [isEditOpen, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false);
  const [hideCoachDialog, { toggle: toggleCoachDialog }] = useBoolean(true);

  useEffect(() => {
        
    if (editStudent?.DateOfBirth !== diffStudent?.DateOfBirth) {
      setHasChange(true);
      return;
    }

    if (editStudent?.Weight !== diffStudent?.Weight) {
      setHasChange(true);
      return;
    }

    if (editStudent?.Height !== diffStudent?.Height) {
      setHasChange(true);
      return;
    }

    if (editStudent?.FirstName !== diffStudent?.FirstName) {
      setHasChange(true);
      return;
    }

    if (editStudent?.Gender !== diffStudent?.Gender) {
      setHasChange(true);
      return;
    }
    
    if (editStudent?.ImageUri !== diffStudent?.ImageUri) {
      setHasChange(true);
      return;
    }
    
    if (editStudent?.LastName !== diffStudent?.LastName) {
      setHasChange(true);
      return;
    }
    
    if (editStudent?.LevelId !== diffStudent?.LevelId) {
      setHasChange(true);
      return;
    }
    
    setHasChange(false);
  }, [editStudent, diffStudent]);

  const studentValidationStatusChanged = (student: Student, isValid: boolean) => {
    setEditStudent(student);
    setIsEditValid(isValid);
  }

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm Consent',
    closeButtonAriaLabel: 'Close',
    subText: 'Please confirm you have obtained consent.',
  };

  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      maxWidth: 900
    },
    header: [
      theme.fonts.xLarge,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });
  
  const startDownload = async (): Promise<number | IFileDownload> => {
    switch (exportType) {
      case ExportType.AthleteList:
        {
          return downloadTournamentParticipants(instance, account!, tournamentId!);
        }
      case ExportType.Credential:
        {
          if (templateId === undefined || templateId === 'usletter') {
            return downloadTournamentBadgeForStudent(instance, account!, tournamentId!, selectedItem!.key);
          }
  
          return downloadSingleTournamentCredential(instance, account!, tournamentId!, templateId!, selectedItem!.key);
        }
    }

    return 400;
  }

  const [hasConfirmedConsent, setHasConfirmedConsent] = useState<boolean>(false);

  return (
    <AuthenticatedTemplate>
      <div style={{ minWidth: isMobile ? 100 : 400, padding: 40 }}>
        {
          items === undefined ?
            <Loader Text='Just a moment...' /> :
            <>
              <Stack style={{ maxWidth: '90vw' }}>
                <Stack.Item align='end'>
                  <SearchBox placeholder="Find student" onChange={(e, n) => doSearch(n)} />
                </Stack.Item>
                <CommandBar
                  items={commandBarItems}
                  farItems={farCommandBarItems} />
                <List
                  columns={columns}
                  items={items}
                  selectionMode={SelectionMode.single}
                  onActiveItemChanged={onActiveItemChanged}
                  onSelectionChanged={(s) => setSelection(s)}
                  enableSort />
              </Stack>
            </>
        }
        <Dialog
          hidden={hideCoachDialog}
          onDismiss={() => { setHasConfirmedConsent(false); toggleCoachDialog(); }}
          dialogContentProps={dialogContentProps}
          modalProps={{
            isBlocking: true,             
            styles: { main: { maxWidth: 550 } }
          }}>
          <Checkbox
            onChange={(ev, checked) => checked === undefined ? setHasConfirmedConsent(false) : setHasConfirmedConsent(checked)}
            label='I have obtained consent to update personal details on behalf of this individual.'
          />
          <DialogFooter>
            <PrimaryButton disabled={!hasConfirmedConsent} onClick={() => { toggleCoachDialog(); proceedToEdit(athletes?.find(a => a.Id === selectedItem?.key)); }} text="Proceed" />
          </DialogFooter>
        </Dialog>
        <Panel
          headerText="Edit athlete"
          type={PanelType.smallFixedFar}
          isOpen={isEditOpen}
          isLightDismiss={true}
          onDismiss={dismissEditPanel}
          closeButtonAriaLabel="Close">
          {isFetchingCustomValues ? <Loader Text='Just a moment...' /> :
            <>
              <StudentEditor
                levels={allLevels}
                programs={allPrograms}
                requestDob
                requestGender
                requestWeight
                requestHeight
                editStudent={editStudent}
                customValues={customValues}
                onModelValidationStatusChanged={studentValidationStatusChanged}
              />
              <br />
              <PrimaryButton disabled={!isEditValid || isSaving || !hasChange} onClick={() => saveStudent()}>Save</PrimaryButton>
            </>
          }
        </Panel>
        <Modal
          isOpen={isDownloadModalOpen}
          onDismiss={hideDownloadModal}
          containerClassName={contentStyles.container}>
          <FileDownloader
            loadingMessage={exportType === ExportType.Credential ? 'Preparing credential, just a moment' : 'Exporting, just a moment...'}
            startDownload={() => startDownload()} />
        </Modal>
      </div>
    </AuthenticatedTemplate>
  )
}

export default AthleteEditor;
