import React, { FC, useEffect, useState } from 'react';
import { Selection, IColumn, ICommandBarItemProps, CommandBar, Modal, FontWeights, Text, mergeStyleSets, getTheme, Stack, CheckboxVisibility, SelectionMode, IPersonaProps, Dropdown, IDropdownOption, PrimaryButton, Checkbox } from '@fluentui/react';
import { useMsal, useAccount } from "@azure/msal-react";
import { addStakeholderToTournament, findStudentByName, getStakeholdersForTournament, IStakeholderViewModel } from '../../../ApiService';
import Loader from '../../../components/Loader';
import List, { IDocument } from '../../../components/List';
import { useBoolean } from '@fluentui/react-hooks';
import { TournamentStakeholderRoles } from '../../../model/TournamentReviewDashboardViewModel';
import StudentPicker from '../../../components/StudentPicker';

interface ITableRow extends IDocument {
    key: string;
    role: string;
    displayName: string;
    email: string;
    membershipId: string;
    studentId: string;
}


interface IData {
  tournamentId?: string;
}

const StakeholderManagement : FC<IData> = ({tournamentId}) => {
    const { instance, accounts } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [items, setItems] = useState<ITableRow[]>();
    const [stakeholders, setStakeholders] = useState<IStakeholderViewModel[]>([]);
    const [isTableLocked, ] = useState<boolean>(false);
    const [selection, setSelection] = useState<Selection>(new Selection());
    const [pendingStakeholder, setPendingStakeholder] = useState<IStakeholderViewModel>({Email: '', DisplayName:'', MembershipId:'',StudentId:'',Role:'', TrainingFacility: ''});
    const [canAdd, setCanAdd] = useState<boolean>(false);

    const [columns, ] = useState<IColumn[]>([           
      {
          key: 'column0',
          name: 'Name',
          fieldName: 'displayName',
          minWidth: 40,
          maxWidth: 200,
          isRowHeader: true,
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true
        },
        {
            key: 'column1',
            name: 'Email',
            fieldName: 'email',
            minWidth: 40,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true
        },
      {
        key: 'column2',
        name: 'Role',
        fieldName: 'role',
        minWidth: 60,
        maxWidth: 85,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true
      }
    ]);
    

    const commandBarItems : ICommandBarItemProps [] = [          
      {
        key:'new',
        text: 'New',
        iconProps: {iconName:'Add'},
        onClick: () => showAddStakeholderModal()
      },
    //   {
    //     key:'edit',
    //     text: 'Edit',
    //     disabled: selection.getSelectedCount() === 0,
    //     iconProps: {iconName:'Edit'},
    //     onClick: () => {}
    //   }
    ];

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        const fetchAsync = async () => {
            var stakeholderResult = await getStakeholdersForTournament(instance, account!, tournamentId!);

            if(typeof stakeholderResult === 'number') {
                setErrorCode(stakeholderResult);
                return;
            } 

            setStakeholders(stakeholderResult);
            setIsLoaded(true);
        }

        if(account) {
            fetchAsync();
        }
    },[instance, account, tournamentId]);

    useEffect(()=> {
        if(stakeholders === undefined) {
          return;
        }

        var tableRows = buildTableRows(stakeholders);
        setItems(tableRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps     
      },[stakeholders]);

      const buildTableRows = (stakeholders: IStakeholderViewModel[]) => {
        var tableRows = new Array<ITableRow>();
            
        for(let stakeholder of stakeholders) {
            let title = stakeholder.DisplayName;

            //stakeholder is a student in the membership
            tableRows.push({
                key: `${stakeholder.MembershipId}_${stakeholder.StudentId}`,
                displayName: title,
                email: stakeholder.Email,
                getTitle: ()=>title,
                membershipId: stakeholder.MembershipId,
                studentId: stakeholder.StudentId,
                role: stakeholder.Role
            });
        }
  
        return tableRows;
      }

    const onActiveItemChanged = (item?: ITableRow, index?: number) => {      
       if(selection === undefined) { //do nothing to keep linter happy

       }
    }

    useEffect(()=> {
        if(pendingStakeholder.MembershipId.length === 0) {
            setCanAdd(false);
            return;
        }

        if(pendingStakeholder.StudentId.length === 0) {
            setCanAdd(false);
            return;
        }

        if(pendingStakeholder.Role.length === 0) {
            setCanAdd(false);
            return;
        }

        setCanAdd(true);
    },[pendingStakeholder]);

    const [isAddStakeholderModalOpen, { setTrue: showAddStakeholderModal, setFalse: hideAddStakeholderModal }] = useBoolean(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

    interface IStakeholderPersonaProps extends IPersonaProps {
        membershipId: string;
        studentId: string;
        trainingFacility: string;
    }
        
    const onResolveSuggestions = (filter: string, selectedItems?: IPersonaProps[] | undefined) : IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
        return new Promise<IPersonaProps[]>(async (resolve, reject) => {
            var results = await findStudentByName(instance, account!, tournamentId!, filter);

            if(typeof results === 'number') {
                reject(results);
                return;
            }
    
            var toReturn : IStakeholderPersonaProps[]  = [];
    
            for(let r of results) {
                let studentId = r.StudentId;
                let membershipId = r.MembershipId;

                //don't allow stakeholders that already exist to be added again
                if(stakeholders?.find(s=>s.MembershipId === membershipId && s.StudentId === studentId) !== undefined) {
                    continue;
                }

                toReturn.push({
                    imageInitials: `${r.DisplayName.split(' ')[0][0]}${r.DisplayName.split(' ')[1][0]}`,
                    text: r.DisplayName,
                    secondaryText: r.Email,
                    showSecondaryText: true,
                    membershipId: r.MembershipId,
                    studentId: r.StudentId,
                    trainingFacility: r.TrainingFacility
                });
            }
            resolve(toReturn);
        });        
    }

    const getStakeholderRoles = () : IDropdownOption[] => {
        var vals = Object.values(TournamentStakeholderRoles);

        var toReturn : IDropdownOption[] = [];
        var count = 0;

        for(let val of vals) {
            toReturn.push({
                key: count,
                text: val
            });

            count++;
        }

        return toReturn;
    }

    const [isAddingStakeholder, setIsAddingStakeholder] = useState<boolean>(false);

    const addStakeholder = async () => {
        if(pendingStakeholder === undefined) {
            return;
        }

        setIsAddingStakeholder(true);

        var result = await addStakeholderToTournament(instance, account!, tournamentId!, pendingStakeholder);

        if (result) {
            var stakeholderResult = await getStakeholdersForTournament(instance, account!, tournamentId!);

            if(typeof stakeholderResult === 'number') {
                setErrorCode(stakeholderResult);
                return;
            } 
            
            setStakeholders(stakeholderResult);
            hideAddStakeholderModal();
        }

        setIsAddingStakeholder(false);
    }

    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);

    return (
        <>
            {isLoaded ?                
                    <Stack tokens={{childrenGap:10}}>
                        <br />
                        <CommandBar
                            items={commandBarItems} />
                            
                        {items !== undefined && stakeholders !== undefined && stakeholders.length > 0 &&
                            <List
                                columns={columns}
                                isLocked={isTableLocked}
                                selectionMode={SelectionMode.single}
                                enableSort
                                checkboxVisibility={CheckboxVisibility.hidden}
                                items={items}
                                onActiveItemChanged={onActiveItemChanged}
                                onSelectionChanged={(s) => setSelection(s)} />
                    }
                    {stakeholders !== undefined && stakeholders.length === 0 &&
                        <div style={{ paddingTop: 20, paddingLeft: 10 }}><Text>No stakeholders yet.</Text></div>
                    }
                    </Stack>                     
                :
                <Loader Text='Just a moment...' />
            }
             <Modal
                isOpen={isAddStakeholderModalOpen}
                onDismiss={hideAddStakeholderModal}                                
                containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span>Add Stakeholder</span>                
                </div>
                <div className={contentStyles.body}>   
                    <Stack tokens={{childrenGap:10}}>
                        <Text>Role</Text>
                        <Dropdown
                            styles={{ dropdownOptionText: { fontSize: 16 }, title: { fontSize: 16 } }}
                            onChange={(e, o) => { setPendingStakeholder({ ...pendingStakeholder!, Role: o!.text }); setAgreeToTerms(o!.text !== TournamentStakeholderRoles.Coach); }}
                            options={getStakeholderRoles()} />
                        <Text>Member search</Text>
                        <StudentPicker
                            tournamentId={tournamentId!}
                            onResolveSuggestions={onResolveSuggestions}
                            onChange={(items) => items?.length === 0 ? setPendingStakeholder(({ ...pendingStakeholder, MembershipId: '', StudentId: '', TrainingFacility: ''})) : setPendingStakeholder(({ ...pendingStakeholder, MembershipId: items![0].membershipId, StudentId: items![0].studentId, TrainingFacility: items![0].trainingFacility }))} />
                        {pendingStakeholder?.TrainingFacility.length > 0 && pendingStakeholder.Role === TournamentStakeholderRoles.Coach &&
                            <Checkbox
                                styles={{ root: { maxWidth: 400 } }}
                                onChange={(ev, checked) => setAgreeToTerms(checked!)}
                                label={`I understand that this stakeholder will be given access to personal information including names, gender and date of birth for participants from ${pendingStakeholder.TrainingFacility}`}
                            />
                        }
                        
                        <PrimaryButton disabled={!canAdd || !agreeToTerms || isAddingStakeholder} onClick={addStakeholder}>Add</PrimaryButton>
                    </Stack>
                </div>
            </Modal>
        </>
    );
}

export default StakeholderManagement;
