import React, { FC, useEffect, useState } from 'react';
import { Dropdown, IDropdownOption, Label, TextField, Text } from '@fluentui/react';
import { Tournament, TournamentLayout, TournamentType } from '../../model/Tournament';
import { GetTournamentLayoutString, GetTournamentTypeString } from '../../Display';
import { CleanId } from '../../Validation';
import EditableImage from '../../pages/Admin/components/EditableImage';
import { StoragePurpose } from '../../Storage';

interface IData {
    tournament: Tournament;
    fixedId: boolean;
    onValidated: (isValid: boolean, tournament: Tournament) => void;
}

const BasicWizard : FC<IData> = ({tournament, onValidated, fixedId}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    useEffect(()=> {
        if(internalTournament.Title.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        if(internalTournament.Description.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        if(internalTournament.StatementDescriptor.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        if(internalTournament.RegistrationDescription?.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        if(internalTournament.Type === TournamentType.InPerson && 
            (internalTournament.Location === undefined || internalTournament.Location.length === 0)) {
            onValidated(false, internalTournament);
            return;
        }

        onValidated(true, internalTournament);
// eslint-disable-next-line react-hooks/exhaustive-deps                 
    },[internalTournament]);    

    const tournamentTypeOptions : IDropdownOption[] = [
        {
            key: TournamentType.InPerson,
            id: TournamentType.InPerson.toString(),
            text: GetTournamentTypeString(TournamentType.InPerson)
        },
        {
            key: TournamentType.Online,
            id: TournamentType.Online.toString(),
            text: GetTournamentTypeString(TournamentType.Online)
        }
    ]

    const tournamentLayoutOptions : IDropdownOption[] = [
        {
            key: TournamentLayout.Compact,
            id: TournamentLayout.Compact.toString(),
            text: GetTournamentLayoutString(TournamentLayout.Compact)
        },
        {
            key: TournamentLayout.Default,
            id: TournamentLayout.Default.toString(),
            text: GetTournamentLayoutString(TournamentLayout.Default)
        }
    ]
    
    const updateTitle = (title: string) => {
        
        var id = internalTournament.Id;

        if (!fixedId) {
            id = CleanId(title);  
        } 

        setInternalTournament(internalTournament => ({ ...internalTournament, Title: title, Id: id }));
    }
  
    return (
      <div style={{display:'flex', flexDirection:'column', gap:5}}>
        <Text variant='large'>Basic details</Text>
            <br />
            <Label required>Image</Label>
            <EditableImage
                purpose={StoragePurpose.Tournament}
                uri={tournament.ImageUri}
                onUriChange={(uri) => setInternalTournament(internalTournament => ({ ...internalTournament, ImageUri: uri }))} />
            
        <Label required>Title</Label>
        <TextField 
            defaultValue={tournament.Title}
            placeholder='Tournament title' 
            onChange={(e, t) => updateTitle(t!)} />

        <TextField disabled label="Tournament Id" readOnly value={internalTournament.Id} />

        <Label required>Support Email</Label>
        <TextField 
            multiline 
            defaultValue={tournament.SupportEmail}
            placeholder='Email address your customers should contact you on for support.' 
            onChange={(e, t)=> setInternalTournament(internalTournament => ({...internalTournament, SupportEmail:t!}))} />

        <Label required>Description</Label>
        <TextField 
            multiline 
            defaultValue={tournament.Description}
            placeholder='General description of your tournament, displayed on the landing page' 
            onChange={(e, t)=> setInternalTournament(internalTournament => ({...internalTournament, Description:t!}))} />

        <Label required>Registration Description</Label>
        <TextField 
            multiline 
            defaultValue={tournament.RegistrationDescription}
            placeholder='Description that is displayed at the top of the registration page' 
            onChange={(e, t) => setInternalTournament(internalTournament => ({ ...internalTournament, RegistrationDescription: t! }))} />
        
        <Label required>Statement Descriptor</Label>
        <TextField 
            defaultValue={tournament.StatementDescriptor}
            maxLength={16}
            placeholder='Brief description to appear on credit card statements of your customers.' 
            onChange={(e, t)=> setInternalTournament(internalTournament => ({...internalTournament, StatementDescriptor:t!}))} />            

        <Label required>Event Layout</Label>
        <Text variant='small'>Compact layout is recommended if you have more than 6 events.</Text>
        <Dropdown 
            key='layout-options'
            options={tournamentLayoutOptions}
            style={{width:300}} 
            selectedKey={internalTournament.Layout}
            onChange={(e,o)=>setInternalTournament(() => ({...internalTournament, Layout: parseInt(o?.id!)}))}/>

        <Label required>Type</Label>
        <Dropdown 
            key='type-options'
            options={tournamentTypeOptions}      
            style={{width:300}} 
            selectedKey={internalTournament.Type}
            onChange={(e,o) => setInternalTournament(internalTournament => ({...internalTournament, Type: parseInt(o!.id!) as TournamentType}))}/>
        
            {internalTournament.Type === TournamentType.Online ?
            null : 
            <>
                <Label required>Address</Label>
                <TextField 
                    defaultValue={tournament.Location}
                    placeholder='Enter the physical address the tournament will be hosted at'
                    onChange={(e,v) => setInternalTournament(internalTournament => ({...internalTournament, Location: v! }))}
                    />
            </>}

      </div>
    );
}

export default BasicWizard;